import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>FISSURE SEALANTS</h2>
      <h3>What are fissure sealants?</h3>
      <p>Fissures are grooves usually found in the chewing surface of back teeth. They are difficult to keep clean so there is more risk of decay. Fissures can be sealed with tough plastic to protect them.</p>
      <p>The plastic is runny at first but sets hard, like paint. Sealants may be see-through or tooth-coloured. They are normally only used for children and only on permanent teeth, not on milk teeth.</p>
      <p>Your dentist will discuss whether your child needs sealants.If they do, you will also have to agree which teeth need to be sealed. The need for sealants will depend on:</p>
      <ul>
        <li>the shape of each tooth</li>
        <li>how much a child's teeth have already decayed</li>
        <li>how much decay a brother or sister has had (because there can be family patterns in tooth decay)</li>
        <li>a child's general health (because general health problems can make it especially important to avoid dental problems)</li>
      </ul>
      <h3>What will my dentist do?</h3>
      <p>The dentist will:</p>
      <ul>
        <li>clean the tooth thoroughly with a rotating brush or rubber polisher</li>
        <li>dry the tooth and keep it dry by putting cotton wool round it and using suction</li>
        <li>dab a mild acid on the tooth and leave it for a short time to make the tooth surface rough (this will not hurt at all)</li>
        <li>wash and dry the tooth by blowing  water and then air onto it</li>
        <li>change the cotton wool rolls around the tooth to keep it dry</li>
        <li>paint the plastic onto the fissures</li>
        <li>harden the plastic by pointing a bright light at it (you will see the dentist and dental nurse protecting  their eyes)</li>
        <li>check that the tooth is comfortable to bite on</li>
        <li>trim the sealant.</li>
      </ul>
      <h3>What are the benefits?</h3>
      <p>Fissure  sealants protect  teeth from decay.</p>
      <p>They can last for years.  Or, if they fall out or wear out, they can usually  be replaced if there is no decay  underneath.</p>
    </LightboxLayout>
  )
}

export default Page